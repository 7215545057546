/* img {
  width: 230px;
  height: 150px;
  object-fit: cover;
} */

h3 {
  margin: 20px;
}

.image-container {
  width: 230px;
  /* background-color: aqua; */
  height: 160px;
  background-size: cover;
  background-position: center;
}


.parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choices-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px; 
  margin: 20px auto;
  height: 36px;
  width: 90%;
  max-width: 486px; /*Matches media query for undo button*/
  
}

.header-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.current-option {
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
  font-size: 1.5em;
  border: 2px solid black;
  border-radius: 10px;
}
.option {
  font-family: 'Poppins';
  font-size: 1.2em;
  font-weight: bold;
}
.cheese-name{
  font-family: 'Poppins';
  font-size: 1.6em;
  font-weight: bold;
}

.grayed-out {
  position: relative;
}

.grayed-out::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(70, 70, 70, 0.7);
}

.choice-overlay {
  position: absolute;
  z-index: 1;
  font-size: 50px ;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: 'Poppins', sans-serif;
}

.undo-button {
  position: fixed;
  left: 10px;
  bottom: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.submit-button {
  position: fixed;
  right: 10px; /* Positioned on the right side of the screen, similar to undo button on the left */
  bottom: 36px;
  cursor: pointer;
  width: 60px;
  height: 40px;
  border-radius: 20px; /* Rounded corners */
  border: none; /* Remove default border */
  background-color: #2c57e5; /* Your preferred color */
  color: white; /* Text color */
  font-size: .7em; /* Adjust as needed */
  font-weight: bold;
  text-align: center;
  line-height: 100%; /* Vertically center the text. Should match the height of the button */
}

.submit-button:disabled {
  background-color: grey;
  color: rgb(99, 99, 99);
  cursor: not-allowed;

}

.overlay-stat-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.92);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-family: 'Poppins', sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.highlight {
  padding: 2px;
  border-radius: 7px;
  border: 3px solid yellow;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html, body, #root, .App {
  height: 99%;
}

@media screen and (min-width: 500px) {
  .undo-button {
    position: absolute;

    left: 50%;
    transform: translate(-240px, 0px); 
  }
  .submit-button {
    position: absolute;
    left: 50%;
    transform: translate(180px, 0px)
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
